/* eslint-disable import/no-anonymous-default-export */

// const APP_API_URL = process.env.REACT_APP_API_URL
const APP_API_URL = "https://test.backend.simplestudying.com/api"
export default {
  APP_API_URL,

  GOOGLE: {
    GAPI_KEY: "",
    reCaptcha: "",
    GA_TRACKING_CODE: "G-59JP7Y5WH2",
  },
};
